import React, { useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../components/Core";
import imgAppStore from "../assets/image/svg/app-store-badge.svg";
import imgPlay from "../assets/image/png/google-play-badge.png";
import Footer from "../components/Footer";
import { device } from "../utils";
import { HelmetTitle } from "../components/HelmetTitle";
import { ManagedFaqCard } from "../components/FaqCard";
import { isAndroid, isIOS } from "react-device-detect";
import {
  APP_STORE_OFFER_LINK,
  AppStoreImg,
  PLAY_STORE_LINK,
  QrAppLinks,
} from "../components/AppLinks";

const FillHeightBox = styled(Box)`
  @media (min-height: 1000px) {
    height: 100vh;
  }
`;

const SectionStyled = styled(Section)`
  border-bottom: 1px solid #eae9f2;
`;

const ButtonContainer = styled(Box)`
  margin-top: 35px;
  display: flex;
  justify-content: center;
`;

const ButtonApp = styled.a`
  padding: 0 4px;
  transition: 0.4s;
  &:visited {
    text-decoration: none;
  }
  &:hover {
    transform: translateY(-8px);
  }

  @media ${device.sm} {
    padding: 0 9px;
  }
`;

const CodeBox = styled(Box)`
  border: 4px dashed #aaa;
  border-radius: 5px;
  background-color: #fff;
  padding: 12px;
  margin: 24px 0 40px;

  @media ${device.sm} {
    padding: 20px;
  }
`;

const CodeText = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 10px;
  text-align: center;

  @media ${device.sm} {
    font-size: 40px;
  }
`;

const BulletedUl = styled.ul`
  margin-top: 16px;

  li {
    margin-bottom: 16px;
    &:before {
      content: "\u2022";
      margin-right: 12px;
    }
  }
`;

const PromoCodePage = ({ pageContext }) => {
  const promo = pageContext.promo;
  useEffect(() => {
    let params = new URLSearchParams(document.location.search);
    const redirect = params.get("redirect");
    if (redirect) {
      if (isIOS) {
        window.location.href = APP_STORE_OFFER_LINK(promo.code);
      } else if (isAndroid) {
        window.location.href = PLAY_STORE_LINK(promo.code);
      }
    }
  }, [promo]);

  return <PromoCode {...promo} />;
};

const DEFAULT_HEADLINE = "Your Offer Awaits";

const isAndroidOrIos = isAndroid || isIOS;

const PromoCode = ({ code, headline = DEFAULT_HEADLINE }) => (
  <FillHeightBox display="flex" flexDirection="column">
    <HelmetTitle title={`Allo — Promo Code: ${code.toUpperCase()}`} />
    <SectionStyled
      display="flex"
      flexDirection="column"
      flex="1"
      justifyContent="center"
    >
      <Container>
        <Row className="justify-content-center mb-5">
          <Col lg={isAndroidOrIos ? "8" : "6"} xl={isAndroidOrIos ? "8" : "6"}>
            <Title pt="5">
              Congrats!
              <br />
              {headline}
            </Title>
            <Text>
              We are excited for you to begin your mindful money practice with
              Allo. Download the app, and use the code below to unlock your
              special offer.
            </Text>

            <CodeBox>
              <CodeText>{code.toUpperCase()}</CodeText>
            </CodeBox>
            {isAndroidOrIos && (
              <ButtonContainer>
                <ButtonApp href={APP_STORE_OFFER_LINK(code)} target="_blank">
                  <AppStoreImg src={imgAppStore} alt="App Store" />
                </ButtonApp>

                <ButtonApp href={PLAY_STORE_LINK(code)} target="_blank">
                  <AppStoreImg src={imgPlay} alt="Play Store" />
                </ButtonApp>
              </ButtonContainer>
            )}
          </Col>
          {!isAndroidOrIos && (
            <Col md="8" lg="6">
              <QrAppLinks
                qrUrl={`https://allo.finance/promo/${code.toLowerCase()}/?redirect=true`}
                iosUrl={APP_STORE_OFFER_LINK(code)}
                campaign={code}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col lg="6" className="mt-3">
            <ManagedFaqCard title={"How do I redeem my offer on iOS?"}>
              <BulletedUl>
                <li>
                  {isAndroidOrIos
                    ? 'Simply press on the "Download on the App Store" button above on your iOS device. '
                    : "Simply scan the QR code above using your iOS device's camera. "}
                  You will be taken to the App Store to install the app &
                  complete your redemption.
                </li>
                <SupportListItem />
              </BulletedUl>
            </ManagedFaqCard>
          </Col>
          <Col lg="6" className="mt-3">
            <ManagedFaqCard title={"How do I redeem my offer on Android?"}>
              <BulletedUl>
                <li>
                  If you haven't already, install the app by{" "}
                  {isAndroidOrIos
                    ? 'pressing on the "Get It On Google Play" button above.'
                    : "scanning the QR code above."}
                </li>
                <li>
                  When you are prompted to purchase an Allo subscription, press
                  on your payment method.
                </li>
                <li>Select "Redeem code" and enter the code above.</li>
                <SupportListItem />
              </BulletedUl>
            </ManagedFaqCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
    <Footer isDark={true} />
  </FillHeightBox>
);

const SupportListItem = () => (
  <li>
    Please contact{" "}
    <a href="mailto:support@allo.finance" onClick={e => e.stopPropagation()}>
      support@allo.finance
    </a>{" "}
    if you need further assistance.
  </li>
);

export default PromoCodePage;
